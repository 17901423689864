.card-title {
    width: 300px;
    flex-grow: 1;
}

.card-container {
    margin: 0px 0px;
}

.card-body {
    padding: 20px;
    background: linear-gradient(to bottom, #E1E8F0, #F9FFF8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #333333;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
}


.card-img-top {
    align-content: center;
    width: 100%;
    height: 250px;
}

.card {
    border-radius: 1rem;
    height: 350px;
    overflow: hidden;
}

.card-responsive {
    width: 650px;
    max-width: 100%;
}

@media (max-width: 600px) {
    .card-responsive {
        width: 300px;
    }
}


.card {
    animation: scaleFadeIn 1s ease-in-out;
}

@keyframes scaleFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}