/* GSC Section */
.gsc-section {
    background-color: #f9f9f9;
    padding: 80px 0;
}

.gsc-h {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.4;
}

.heading-main {
    font-weight: bold;
}

.heading-sub {
    font-style: italic;
    color: #666;
}


.gsc-p {
    font-size: 1.1rem;
    color: #4b4141;
    line-height: 1.8;
}

.phone-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.phone-link img {
    margin-right: 10px;
}