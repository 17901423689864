/* Booking Container specific styles */
.booking-pcontainer {
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 20px;
    background-color: #f8f9fa;
    border-radius: 65px;
    box-shadow: 0 0 300px rgba(0, 0, 0, 100);
}

.elementor-icon-box-title {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
}

.elementor-icon-box-description {
    text-align: center;
    color: #6c757d;
    margin-bottom: 30px;
    font-size: 1rem;
}

.elementor-heading-title {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
    font-size: 1.75rem;
}

.description-text {
    text-align: center;
    color: #6c757d;
    font-size: 1rem;
}

.form-control,
.form-select {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 15px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:focus,
.form-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
}

.checkbox-group-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.checkbox-group input[type="checkbox"] {
    margin-right: 10px;
}

.checkbox-group label {
    font-size: 1rem;
    color: #343a40;
}

textarea.form-control {
    height: 150px;
    resize: none;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.btn-primary:hover {
    background-color: green;
    color: white;
}

.booking-pcontact {
    width: 500px;
    max-width: 750px;
    padding: 15px;
    margin: auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.booking-ptext-strong {
    font-size: 1.1rem;
    color: #343a40;
    margin-bottom: 10px;
}

.booking-ptext {
    color: #6c757d;
    font-size: 1rem;
    margin-bottom: 20px;
}

.booking-ph1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.booking-pphone {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
}

.booking-pphone:hover {
    color: green;
}

.booking-pbtn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.booking-pbtn-primary:hover {
    background-color: green;
    color: white;
}

.green-button {
    border-radius: 50px;
    padding: 10px 20px;
    background: linear-gradient(to bottom, #4CAF50, #388E3C);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.green-button:hover {
    background: linear-gradient(to bottom, #388E3C, #2E7D32);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.15);
}


@media (max-width: 768px) {

    .booking-pcontainer,
    .booking-pcontact {
        width: 90%;
        max-width: 100%;
    }

    .form-control,
    .form-select,
    textarea {
        width: 100%;
    }

    .checkbox-group {
        display: flex;
        flex-direction: column;
    }

    .mb-3 {
        width: 100%;
    }
}