/* about Section */
.about-section {
    background-color: #f9f9f9;
    padding: 80px 0;
    border-radius: 65px;
}

.about-h {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.4;
}

.heading-main {
    font-weight: bold;
}

.heading-sub {
    font-style: italic;
    color: #666;
}

.about-p {
    font-size: 1.1rem;
    color: #4b4141;
    line-height: 1.8;
}

.phone-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 30px;
}

.phone-link img {
    margin-right: 10px;
}

.mail-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 40px;
}

/* Add responsiveness */
@media (max-width: 768px) {
    .about-section .d-flex {
        flex-direction: column;
        align-items: center;
    }

    .about-section img {
        width: 100%;
        height: auto;
    }

    .about-p {
        text-align: center;
    }
}