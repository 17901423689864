.booking-section {
    text-align: center;
    padding: 0 20px;
}

.main-title {
    margin-bottom: 0;
    font-weight: bold;
    margin-top: 70px;
}

.booking-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.booking-content {
    flex: 1;
    max-width: 500px;
    margin: 5%;
    text-align: left;
}

.booking-title {
    margin-bottom: -20px;
    font-weight: bold;
    color: white;
}

.booking-description {
    color: rgba(255, 255, 255, 0.4);
    margin: 20px 0;
}

.booking-button {
    border-radius: 50px;
    padding: 10px 20px;
}

.booking-image-container {
    flex: 1;
    text-align: center;
}

.booking-image {
    max-width: 100%;
    height: auto;
    margin: 5%;
}

@media (max-width: 768px) {
    .booking-container {
        flex-direction: column;
        text-align: center;
    }

    .booking-content {
        max-width: 100%;
        margin: 20px 0;
    }

    .booking-image {
        margin: 20px 0;
    }
}