/* Navbar */
.navbar {
    background-color: #FFFFFF;
    border-bottom: 3px solid #ddd;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    outline: none;
}

.nav-link {
    color: #333;
    font-weight: 500;
    transition: color 0.3s, background-color 0.3s;
}

.nav-link:hover,
.nav-link:focus {
    color: #000;
}

.dropdown-menu {
    display: none;
    border-radius: 25px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    color: #333;
    border-radius: 65px;
    font-weight: 400;
    transition: color 0.3s, background-color 0.3s;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: white;
    border-radius: 65px;
    background-color: #000000;
}

.navbar-nav>li>.nav-link.active {
    color: #000;
    font-weight: bold;
    background-color: transparent;
}


.dropdown-item.active {
    color: #000;
    font-weight: bold;
    background-color: transparent;
}

.dropdown-item.active:hover,
.dropdown-item.active:focus {
    color: #000;
    background-color: #f8f9fa;
}

.navbar-nav>.nav-item>.nav-link>img {
    margin-right: 5px;
}

.fixed-top {
    align-items: center;
    flex-direction: column;
    position: fixed;
}