/* Hero Section */
/* Ensures the heroBG spans the full width */
.heroBG {
    background-color: #B1D8FC;
    padding: 20px 0;
    /* Added padding to the top and bottom */
    /* border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px; */
    border-radius: 65px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.hero-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: 84px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .content {
        flex-direction: row;
    }
}

.left-content,
.right-content {
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {

    .left-content,
    .right-content {
        margin-bottom: 0;
    }
}

.right-content img {
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: 768px) {
    .right-content img {
        max-width: 80%;
        height: auto;
    }
}

.btn-img {
    width: 10px;
    height: 15px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.custom-button {
    border-radius: 50px;
    padding: 10px 20px;
    background: linear-gradient(to bottom, #333, #000);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.custom-button:hover {
    background: linear-gradient(to bottom, #555, #222);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.15);
}




/* Services  */
.service-h {
    margin-top: 70px;
}

.service-p {
    margin-top: 17px;
    font-weight: 600;
}

.service-b {
    background-image: url(../Images/Homepage/ServiceB.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.service-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 90%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}



/* booking section */

.booking-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.booking-b {
    background-color: #323232;
    padding: 20px;
    border-radius: 65px;
}

/* Engineers.css */

/* Ensure images don't exceed the specified size */
.company-logo {
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
    margin: 5px;
}

/* Container to adjust flex properties for responsive design */
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Media query for small screens (max-width: 768px) */
@media (max-width: 768px) {
    .image-container {
        justify-content: center;
    }

    .company-logo {
        flex: 0 1 calc(50% - 10px);
        /* Two images per row with some margin */
        margin: 5px;
    }
}

/* Media query for very small screens (max-width: 480px) */
@media (max-width: 480px) {
    .image-container {
        justify-content: center;
    }

    .company-logo {
        flex: 0 1 calc(50% - 10px);
        /* Two images per row with some margin */
        margin: 5px;
    }
}