/* footer */

.footerBG {
    background-color: #323232;
    padding: 20px 0;
    border-top-left-radius: 65px;
    border-top-right-radius: 65px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-links a {
    margin: 0 10px;
}

.btn {
    display: inline-block;
    margin: 5px 0;
}


.btn img {
    width: 10px;
    height: 15px;
    margin-left: 8px;
    margin-bottom: 0px;
}

.m {
    margin: 0;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
    .footer-container {
        padding: 0 10px;
    }

    .d-flex {
        flex-direction: column;
        align-items: center;
    }

    .justify-content-between {
        text-align: center;
    }

    .footer-links a {
        display: block;
        margin: 5px 0;
    }
}