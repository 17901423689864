.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  max-width: 100%;
  border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px;
  height: 84px;
  background: linear-gradient(90deg, #71fff5, #0b30fb 100%, #1938e4 0);
  z-index: 102;
  transition: width 0.5s linear;
}

p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.text-shadow-custom {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

html,
body {
  overflow-x: hidden;
  /* Prevent horizontal overflow */
  margin: 0;
  padding: 0;
  width: 100%;
}

.header,
.footer {
  width: 100%;
}